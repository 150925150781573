@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';

.msrpPrice {
  font-size: @font-size-base;
  line-height: 1;
  color: var(--text-secondary, @color-primary-gray);
  font-weight: @font-weight-regular;
  margin-bottom: 8px;

  display: flex;
  align-items: center;
  gap: 2px;

  span {
    margin-right: 4px;
  }
}

.tipText {
  max-width: 180px;
}

.tooltip {
  display: none;
  @media (hover: hover) {
    display: inline-flex;
  }
}
