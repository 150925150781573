@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

:global {
  .digital-greeting-card-modal {
    width: 1024px;
    max-width: 100%;
    border-radius: 24px;
    overflow: hidden;

    .mobile({
      max-height: 100dvh;
      border-radius: 0;
      overflow: scroll;
    });

    .modal-content {
      padding: 48px;

      .mobile({
        min-height: 100dvh;
        padding: 48px 24px;
        border-radius: 0;
      });
    }

    .modal-close {
      display: none;
    }
  }

  .quick-checkout-modal {
    width: 536px;
    max-width: 100%;
    border-radius: 24px;
    overflow: hidden;

    .mobile({
      max-height: 100dvh;
      border-radius: 0;
      overflow: scroll;
    });

    .modal-content {
      padding: 48px;

      .mobile({
        min-height: 100dvh;
        padding: 48px 24px;
        border-radius: 0;
      });
    }

    .modal-close {
      top: 16px;
      right: 16px;
    }
  }

  .registry-product-purchased-modal {
    width: 536px;
    max-width: 100%;
    border-radius: 24px;
    overflow: hidden;

    .mobile({
      max-height: 100dvh;
      border-radius: 0;
      overflow: scroll;
    });

    .modal-content {
      padding: 72px 48px 48px;

      .mobile({
        min-height: 100dvh;
        padding: 48px 24px;
        border-radius: 0;
      });
    }

    .modal-close {
      top: 16px;
      right: 16px;
    }
  }
}
