@import (reference) "../../../../stylesheets/core.less";
// styles for our busy loader that appears during operations that take a moment to complete
.zola-loader {
  padding-top: 25px;
  @media(min-width: @screen-md-min) {
    padding-top: 200px;
    .modernizr-cssanimations & {
      height: 325px;
    }
  }
  margin: 0 auto;
  height: 125px;
  width: 20px;
  position: relative;
  .petal {
    position: absolute;
    width: 20px;
    height: 60px;
    background-size: auto 30px;
    background-repeat: no-repeat;
    animation: spin 1.5s infinite ease;
    &:nth-of-type(2) { animation-delay: 0.1s; }
    &:nth-of-type(3) { animation-delay: 0.2s; }
    &:nth-of-type(4) { animation-delay: 0.3s; }
    &:nth-of-type(5) { animation-delay: 0.4s; }
    &:nth-of-type(6) { animation-delay: 0.5s; }
    &:nth-of-type(7) { animation-delay: 0.6s; }
    &:nth-of-type(8) { animation-delay: 0.7s; }
    &:nth-of-type(4n+1) {
      background-image: url("../../../../assets/images/spinners/petal1.svg");
    }
    &:nth-of-type(4n+2) {
      background-image: url("../../../../assets/images/spinners/petal2.svg");
    }
    &:nth-of-type(4n+3) {
      background-image: url("../../../../assets/images/spinners/petal3.svg");
    }
    &:nth-of-type(4n+4) {
      background-image: url("../../../../assets/images/spinners/petal4.svg");
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
  // fallback to animated gif we have no CSS animation support
  // NOTE: this only supports the standard sized, dark spinner (for now)
  .modernizr-no-cssanimations & {
    margin: auto;
    .square(50px);
    background-image: url("../../../../assets/images/spinners/dark.gif");
  }
}