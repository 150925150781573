@import (reference) '~@zola/zola-ui/src/styles/common/_variables';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) './registryTiles.module.less';

// This sets the width for container queries used in the tiles tiles
.registryTilesContainer {
  container-type: inline-size;
  container-name: registry-tiles;
}

// This sets the layout for the tiles, including borders
.registryTilesLayout {
  margin: 0 -20px;
  border-top: var(--border-grid, 1px solid @color-black-100);

  @container registry-tiles (min-width: @registry-tiles-three-tile-breakpoint) {
    margin: 0;
    border-top: none;
  }
}
