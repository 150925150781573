@import (reference) '~@zola/zola-ui/src/styles/common/_variables';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

// Matching emotion variable: THREE_TILE_BREAKPOINT
@registry-tiles-three-tile-breakpoint: 586px; // at or above 506px, 3 columns
@registry-tiles-four-tile-breakpoint: 992px - 74px - 80px - 16px; // at or above 822px, 4 columns

// Originally, this was set to 1200px - 74px - 80px - 16px (post-auth nav, gutters, scrollbar)
// But that had some very narrow tiles so I just changed it to 1200px which makes each
// tile around 225px wide (gotta count grid gap)
@registry-tiles-five-tile-breakpoint: 1200px;

.registryTilesGrid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-right: calc(var(--border-grid-width, 1px) * -1);

  @container registry-tiles (min-width: @registry-tiles-three-tile-breakpoint) {
    row-gap: 24px;
    column-gap: 16px;
    margin-right: 0;
    grid-template-columns: repeat(3, 1fr);
  }

  @container registry-tiles (min-width: @registry-tiles-four-tile-breakpoint) {
    grid-template-columns: repeat(4, 1fr);
  }

  @container registry-tiles (min-width: @registry-tiles-five-tile-breakpoint) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.instantRegistryTile {
  grid-column: span 2;

  @container registry-tiles (min-width: @registry-tiles-three-tile-breakpoint) {
    grid-row: 1 / 1;
    // 3 columns
    grid-column: 2 / span 2;
  }

  @container registry-tiles (min-width: @registry-tiles-four-tile-breakpoint) {
    grid-row: 1 / 1;
    // 4 columns
    grid-column: 3 / span 2;
  }

  @container registry-tiles (min-width: @registry-tiles-five-tile-breakpoint) {
    // 5 columns
    grid-column: 4 / span 2;
  }
}
